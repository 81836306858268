<template>
  <div>
    <div>
      <img src="https://water-1302249616.cos.ap-nanjing.myqcloud.com//2021-12-07/b1698ab7035c46d49b8cec8e0dc8ee0b-banner_HZ云服.jpg" alt style="width:100%">
    </div>
    <div class="tit-i">
      <img src="../assets/Group.png" alt>
      <h3>和仲服务</h3>
      <img src="../assets/Group Copy.png" alt>
      <h5>
        <span>SERVICE</span>
      </h5>
    </div>
    <div style="display:flex;width:1216px;margin:0 auto;flex-wrap: wrap;margin-bottom:60px">
      <template v-for="subitem in data">
        <div :key="subitem.id">
          <div class="service" style="text-align:center;margin:10px 0;">
            <router-link :to="'/servicedetails?id='+ subitem.id">
              <el-image
                :src="subitem.imgUrl"
                fit="cover"
                :lazy="true"
                alt
                style="width:270px;height:340px;border-radius:10px;margin:0 17px;box-shadow:0 2px 12px 0 rgb(0 0 0 / 20%)"
              /></router-link>
            <router-link :to="'/servicedetails?id='+ subitem.id">
              <h3>{{ subitem.title }}</h3>
            </router-link>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { subpage } from '../api/pc/home'
export default {
  data() {
    return {
      data: []
    }
  },
  created() {
    subpage(2)
      .then(res => {
        console.log('res', res)
        // res.data.item.parentDOS[1].subitemEntities.push(res.data.item.parentDOS[1].subitemEntities[0])
        this.data = res.data.items[0].subitemEntities
      })
      .catch(e => {
        console.error(e)
      })
    // for (let i = 0; i < this.list.length; i++) {
    //   const item = this.list[i];
    //   console.log(item);
    // }
  }
}
</script>

<style  lang="scss" scoped>
.tit-i {
  padding: 20px 0;
  text-align: center;
  padding: 50px 0;
  line-height: 36px;
  overflow: hidden;
  h3 {
    font-size: 30px;
    color: #002;
    margin-bottom: 0px;
    display: inline-block;
  }
  h5 {
    font-size: 20px;
    color: #ffffff;
    text-transform: uppercase;
    font-family: Arial;
    margin-top: 10px;
    span {
      color: #e4392a;
    }
  }
  em {
    width: 45px;
    border-bottom: 1px solid #7f7f90;
    display: inline-block;
  }
}
.ul-icon-i {
  margin-bottom: 70px;
  .li {
    float: left;
    overflow: hidden;
    text-align: center;
    margin-top: -29px;
    margin-left: 62px;
  }
}
</style>
